export default {
    root: {
        width: 34 + 11 * 2,
        height: 14 + 11 * 2,
        padding: 11
    },
    switchBase: {
        padding: 8
    }
  };
  