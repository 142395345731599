import React, { Fragment } from 'react';
import { AppBar, Toolbar, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Switch, Route, Redirect, Link as RouterLink } from 'react-router-dom';
import UsersEmail from './UsersEmail';
import UsersResetPassword from './UsersResetPassword';
import EmailSent from './EmailSent';
import RequestResetPassword from './RequestResetPassword';
import Signup from './Signup';
import Login from './Login';

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: 'none'
    },
    center: {
        justifyContent: 'center'
    }
}));

const Landing = () => {
    const classes = useStyles();

    return (
        <Fragment>
            <AppBar className={classes.root} color="primary">
                <Toolbar className={classes.center}>
                    <Link color="inherit" to="/" variant="h5" underline="none" component={RouterLink}>Ouvertures CJFTIR</Link>
                </Toolbar>
            </AppBar>
            <Switch>
                <Route exact path='/users/email' component={UsersEmail} />
                <Route exact path='/users/resetPassword' component={UsersResetPassword} />
                <Route exact path='/emailSent' component={EmailSent} />
                <Route exact path='/requestResetPassword' component={RequestResetPassword} />
                <Route exact path='/signup' component={Signup} />
                <Route exact path='/' component={Login} />
                <Route render={() => <Redirect to="/" />} />
            </Switch>
        </Fragment>
    );
};

export default Landing;