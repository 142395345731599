import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { TextField, Box } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import gradients from 'utils/gradients';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { useHistory } from 'react-router';
import Base from './Base';
import ButtonWithCircularProgress from 'components/ButtonWithCircularProgress';

const EndSignupMutation = loader('graphql/mutations/EndSignup.graphql');

const useStyles = makeStyles(theme => ({
    icon: {
        backgroundImage: gradients.green,
        color: theme.palette.white,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
        position: 'absolute',
        top: -32,
        left: theme.spacing(3),
        height: 64,
        width: 64,
        fontSize: 32
    },
    loginForm: {
        marginTop: theme.spacing(3)
    },
    fields: {
        margin: theme.spacing(-1),
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            flexGrow: 1,
            margin: theme.spacing(1)
        }
    }
}));

const UsersGoogle = () => {
    const code = new URL(window.location.href).searchParams.get('code');
    const classes = useStyles();
    const client = useApolloClient();
    const history = useHistory();
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [endSignup, { loading }] = useMutation(EndSignupMutation, {
        onCompleted: () => {
            client.writeData({ data: { isConnected: true } });
            history.push('/');
        },
        onError: () => {
            history.push('/');
        }
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        endSignup({ variables: { input: { token: code, password: password } } });
    };

    return (
        <Base title="Terminer mon inscription" subtitle="Choissisez un mot de passe" icon={<LockIcon className={classes.icon} />}>
            <form className={classes.loginForm} onSubmit={handleSubmit}>
                <div className={classes.fields}>
                    <TextField fullWidth label="Mot de passe" name="password" type="password" variant="outlined"
                        value={password} onChange={e => setPassword(e.target.value)}
                        error={password.length < 8} helperText={password.length < 8 ? "Le mot de passe doit faire minimum 8 caractères.": null} />
                    <TextField fullWidth label="Confirmer le mot de passe" name="passwordConfirm" type="password" variant="outlined"
                        value={passwordConfirm} onChange={e => setPasswordConfirm(e.target.value)}
                        error={password !== passwordConfirm} helperText={password !== passwordConfirm ? "Le mot de passe ne correspond pas.": null} />
                </div>
                <Box marginTop={2}>
                    <ButtonWithCircularProgress size="large" type="submit" loading={loading} disabled={password === "" || passwordConfirm === "" || loading || password.length < 8 || password !== passwordConfirm}
                        label="Terminer mon inscription" fullWidth />
                </Box>
            </form>
        </Base>
    );
};

export default UsersGoogle;