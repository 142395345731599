
const formatName = ({firstName, lastName}) => {
    return `${lastName.toUpperCase()} ${firstName.charAt(0).toUpperCase() + firstName.slice(1)}`;
};

const formatFirstName = ({ firstName }) => {
    return `${firstName.charAt(0).toUpperCase() + firstName.slice(1)}`;
};

const formatLastName = ({ lastName }) => {
    return `${lastName.toUpperCase()}`;
};

export { formatName, formatFirstName, formatLastName };
