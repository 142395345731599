import React, { Fragment, useEffect } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Hidden, Drawer, Divider, Paper, Typography, List, ListItem, Button, colors } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import BarChartIcon from '@material-ui/icons/BarChart';
import HistoryIcon from '@material-ui/icons/History';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { formatFirstName } from 'utils/formatName';
import PDF from 'files/Planning Ouvertures CJFTir-Connexion.pdf';

const RequestRemovalCountQuery = loader('graphql/queries/RequestRemovalCount.graphql');

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        overflowY: 'auto',
        zIndex: 3,
        width: 256,
        minWidth: 256,
        flex: '0 0 auto',
        '@media print ': {
            display: 'none'
        }
    },
    content: {
        padding: theme.spacing(2)
    },
    name: {
        marginTop: theme.spacing(1)
    },
    divider: {
        marginTop: theme.spacing(2)
    },
    navigation: {
        marginTop: theme.spacing(2)
    },
    button: {
        color: colors.blueGrey[800],
        padding: '8px 6px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightMedium
    },
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0
    },
    icon: {
        color: theme.palette.icon,
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1)
    },
    active: {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
        '& $icon': {
            color: theme.palette.primary.main
        }
    },
    label: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto'
    },
    l: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 0,
        flexShrink: 0,
        lineHeight: '10px',
        fontSize: '10px',
        height: 20,
        minWidth: 20,
        whiteSpace: 'nowrap',
        borderRadius: 10,
        padding: theme.spacing(0.5),
        backgroundColor: colors.red[500],
        color: '#FFF'
    }
}));

const Link = ({ title, to, exact, icon: Icon, label: Label }) => {
    const classes = useStyles();

    return (
        <ListItem className={classes.item} disableGutters>
            <Button activeClassName={classes.active} className={classes.button} component={NavLink} exact={exact} to={to}>
                <Icon className={classes.icon} />
                {title}
                {Label && <span className={classes.label}> <Label /> </span>}
            </Button>
        </ListItem>
    );
};

const RemovalRequest = () => {
    const { data } = useQuery(RequestRemovalCountQuery);
    const classes = useStyles();

    return (
        <Link title="Demandes de retraits" exact to="/removalRequest" icon={RemoveCircleOutlineOutlinedIcon} label={() => {
            if (data && data.requestRemovalCount && data.requestRemovalCount.count !== 0)
                return <Typography className={classes.l}>
                    {data.requestRemovalCount.count > 9 ? "+9" : data.requestRemovalCount.count}
                </Typography>
            return null;
        }} />
    );
};

const NavBar = ({ user, openMobile, onMobileClose }) => {
    const classes = useStyles();
    const location = useLocation();

    useEffect(() => {
        if (openMobile) {
            onMobileClose && onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const navbarContent = (
        <div className={classes.content}>
            <Typography className={classes.name} variant="h4" align="center">{formatFirstName(user)}</Typography>
            <List onClick={() => onMobileClose()}>
                <Link title="Mon compte" exact to="/account" icon={SettingsIcon} />
                <Link title="Se déconnecter" exact to="/logout" icon={ExitToAppIcon} />
            </List>
            <Divider className={classes.divider} />
            <nav className={classes.navigation}>
                <Typography variant="overline">{'menu'}</Typography>
                <List onClick={() => onMobileClose()}>
                    <Link title="Calendrier" to="/c" icon={CalendarIcon} />
                    {user.permissions.includes('user_list') &&
                        <Link title="Utilisateurs" exact to="/users" icon={PersonOutlineIcon} />
                    }
                    {user.permissions.includes('user_permissions') &&
                        <Link title="Droits utilisateurs" exact to="/permissions" icon={SupervisorAccountOutlinedIcon} />
                    }
                    {user.permissions.includes('participant_list') &&
                        <Link title="Intervenants" exact to="/participants" icon={PersonOutlineIcon} />
                    }
                    {user.permissions.includes('opening_remove') && <RemovalRequest />}
                    {user.permissions.includes('opening_stats') &&
                        <Link title="Statistiques" exact to="/stats" icon={BarChartIcon} />
                    }
                    {user.permissions.includes('history_list') &&
                        <Link title="Historique" exact to="/history" icon={HistoryIcon} />
                    }
                    {user.permissions.includes('email') &&
                        <Link title="Envoi mail" exact to="/email" icon={MailOutlineIcon} />
                    }
                    <ListItem className={classes.item} disableGutters>
                        <Button className={classes.button} component="a" to={"#"} href={PDF} target="_blank">
                            <DescriptionOutlinedIcon className={classes.icon} />
                            {"Notice"}
                        </Button>
                    </ListItem>
                </List>
            </nav>
        </div>
    );

    return (
        <Fragment>
            <Hidden lgUp>
                <Drawer anchor="left" onClose={onMobileClose} open={openMobile} variant="temporary">
                    <div className={classes.root}>
                        {navbarContent}
                    </div>
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Paper className={classes.root} elevation={1} square>
                    {navbarContent}
                </Paper>
            </Hidden>
        </Fragment>
    );
};

export default NavBar;