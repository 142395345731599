import React from 'react';
import { makeStyles } from '@material-ui/styles';
import SendIcon from '@material-ui/icons/Send';
import gradients from 'utils/gradients';
import Base from './Base';


const useStyles = makeStyles(theme => ({
    icon: {
        backgroundImage: gradients.orange,
        color: theme.palette.white,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
        position: 'absolute',
        top: -32,
        left: theme.spacing(3),
        height: 64,
        width: 64,
        fontSize: 32
    }
}));

const EmailSent = () => {
    const classes = useStyles();

    return (
        <Base title="Mail envoyé" subtitle="Un mail vous a été envoyé, consultez votre messagerie." icon={<SendIcon className={classes.icon} />} />
    );
};

export default EmailSent;