import React, { useState, lazy, Suspense } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { UserContext } from 'context';
import Cookies from 'universal-cookie';
import { Button, LinearProgress, Box } from '@material-ui/core';
import TopBar from './components/TopBar';
import NavBar from './components/NavBar';
const Home = lazy(() => import('./scenes/Home'));
const Account = lazy(() => import('./scenes/Account'));
const Users = lazy(() => import('./scenes/Users'));
const Permissions = lazy(() => import('./scenes/Permissions'));
const User = lazy(() => import('./scenes/User'));
const Participants = lazy(() => import('./scenes/Participants'));
const RemovalRequest = lazy(() => import('./scenes/RemovalRequest'));
const Histories = lazy(() => import('./scenes/Histories'));
const Logout = lazy(() => import('./scenes/Logout'));
const Stats = lazy(() => import('./scenes/Stats'));
const Email = lazy(() => import('./scenes/Email'));

const cookies = new Cookies();

const LogoutUserMutation = loader('graphql/mutations/LogoutUser.graphql');
const CurrentUserQuery = loader('graphql/queries/CurrentUser.graphql');

const useStyles = makeStyles(() => ({
    root: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
    },
    container: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden'
    },
    navBar: {
        zIndex: 3,
        width: 256,
        minWidth: 256,
        flex: '0 0 auto'
    },
    content: {
        overflowY: 'auto',
        flex: '1 1 auto'
    }
}));

const MainApp = () => {
    const classes = useStyles();
    const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
    const { data, error, loading } = useQuery(CurrentUserQuery);
    const [logout] = useMutation(LogoutUserMutation,
        {
            onCompleted: () => {
                window.location.reload();
            },
            onError: () => {
                if (process.env.NODE_ENV === 'production') {
                    cookies.remove('id', { httpOnly: false, domain: process.env.REACT_APP_BASE_URL });
                }
                if (process.env.NODE_ENV === 'development') {
                    cookies.remove('id', { httpOnly: false });
                }
                window.location.reload();
            }
        });

    if (loading)
        return (
            <div className={classes.root}>
                <TopBar onOpenNavBarMobile={() => setOpenNavBarMobile(true)} />
                <LinearProgress />
            </div>
        );

    if (error) {
        return `Error! ${error.message}`;
    }

    const { user } = data;
    if (user === null) {
        //window.location.reload();
    }

    if (user.status === 'DELETED')
        return (
            <Box height="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <div>Compte supprimé</div>
                <Button onClick={() => logout()}>Retourner à l'écran de connexion</Button>
            </Box>
        );

    return (
        <UserContext.Provider value={user}>
            <div className={classes.root}>
                <TopBar onOpenNavBarMobile={() => setOpenNavBarMobile(true)} />
                <div className={classes.container}>
                    <NavBar user={user} onMobileClose={() => setOpenNavBarMobile(false)} openMobile={openNavBarMobile} />
                    <div className={classes.content}>
                        <Suspense fallback={<LinearProgress />}>
                            <Switch>
                                <Route exact path='/c' component={Home} />
                                <Route path='/c/:year/:month' component={Home} />
                                <Route path='/logout' component={Logout} />
                                <Route path='/account' component={Account} />
                                <Route exact path='/users/email' component={Logout} />
                                <Route exact path='/users/resetPassword' component={Logout} />
                                {user.permissions.includes('user_list') && <Route exact path='/users' component={Users} />}
                                {user.permissions.includes('user_permissions') && <Route exact path='/permissions' component={Permissions} />}
                                {(user.permissions.includes('user_update') ||
                                    user.permissions.includes('user_permissions') ||
                                    user.permissions.includes('user_delete')) &&
                                    <Route path='/users/:userId' component={User} />}
                                {user.permissions.includes('participant_list') && <Route path='/participants' component={Participants} />}
                                {user.permissions.includes('opening_remove') && <Route path='/removalRequest' component={RemovalRequest} />}
                                {user.permissions.includes('history_list') && <Route path='/history' component={Histories} />}
                                {user.permissions.includes('opening_stats') && <Route path='/stats' component={Stats} />}
                                {user.permissions.includes('email') && <Route path='/email' component={Email} />}
                                <Route render={() => <Redirect to="/c" />} />
                            </Switch>
                        </Suspense>
                    </div>
                </div>
            </div>
        </UserContext.Provider>
    );
};

export default MainApp;