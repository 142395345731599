import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { AppBar, IconButton, Toolbar, Hidden, Link } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu'

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: 'none',
        zIndex: 2,
        position: 'relative',
        '@media print ': {
            display: 'none'
        }
    },
    flexGrow: {
        flexGrow: 1
    }
}));

const TopBar = ({ onOpenNavBarMobile }) => {
    const classes = useStyles();

    return (
        <AppBar className={classes.root} color="primary">
            <Toolbar variant="dense" disableGutters>
                <div className={classes.flexGrow} >
                    <Hidden lgUp>
                        <IconButton color="inherit" onClick={onOpenNavBarMobile}>
                            <MenuIcon />
                        </IconButton>
                    </Hidden>
                </div>
                <Link color="inherit" to="/" variant="h5" underline="none" component={RouterLink}>Ouvertures CJFTIR</Link>
                <div className={classes.flexGrow} />
            </Toolbar>
        </AppBar>
    );
};

export default TopBar;