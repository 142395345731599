import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import MainApp from './scenes/MainApp';
import Landing from './scenes/Landing';
import { loader } from 'graphql.macro';

const LocalIsConnectedQuery = loader('graphql/queries/LocalIsConnected.graphql');

const App = () => {
    const { data, error, loading } = useQuery(LocalIsConnectedQuery);
    if (loading || error) return null;

    return data.isConnected ? <MainApp /> : <Landing />;
};

export default App;