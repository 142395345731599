import React from 'react';

const UserContext = React.createContext(null);
const CellPopupContext = React.createContext({
    popup: null,
    setPopup: () => { }
});
const EventPopupContext = React.createContext({
    event: null,
    setEvent: () => { }
});

export { UserContext, CellPopupContext, EventPopupContext };