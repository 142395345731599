import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './normalize.css';
import 'simplebar/dist/simplebar.min.css';
import App from './App';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { ApolloProvider } from '@apollo/react-hooks';
import { BrowserRouter } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Settings } from 'luxon';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import fragmentTypes from './fragmentTypes.json';
import theme from './theme';

const cookies = new Cookies();
Settings.defaultZoneName = 'Europe/Paris';
Settings.defaultLocale = 'fr';

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: fragmentTypes
});

const cache = new InMemoryCache({
    addTypename: true,
    fragmentMatcher
});
cache.writeData({
    data: {
        isConnected: cookies.get('id') ? true : false
    }
});

const client = new ApolloClient({
    link: new HttpLink({
        uri: process.env.REACT_APP_REMOTE_API,
        credentials: 'include'
    }),
    cache: cache,
    resolvers: {}
});

ReactDOM.render(
    <BrowserRouter>
        <ApolloProvider client={client}>
            <ThemeProvider theme={theme}>
                <SnackbarProvider autoHideDuration={4000}>
                    <App />
                </SnackbarProvider>
            </ThemeProvider>
        </ApolloProvider>
    </BrowserRouter>
    , document.getElementById('root')
);