import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Divider, Link, TextField, Box } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import gradients from 'utils/gradients';
import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { useHistory } from 'react-router';
import Base from './Base';
import ButtonWithCircularProgress from 'components/ButtonWithCircularProgress';

const RequestResetPasswordMutation = loader('graphql/mutations/RequestResetPassword.graphql');

const useStyles = makeStyles(theme => ({
    icon: {
        backgroundImage: gradients.green,
        color: theme.palette.white,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
        position: 'absolute',
        top: -32,
        left: theme.spacing(3),
        height: 64,
        width: 64,
        fontSize: 32
    },
    loginForm: {
        marginTop: theme.spacing(3)
    },
    divider: {
        margin: theme.spacing(2, 0)
    },
    fields: {
        margin: theme.spacing(-1),
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            flexGrow: 1,
            margin: theme.spacing(1)
        }
    },
    links: {
        maxWidth: '100%',
        display: 'flex',
        '& > *': {
            flexGrow: 1,
            flexBasis: '50%',
            width: '50%'
        }
    }
}));

const RequestResetPassword = () => {
    const classes = useStyles();
    const history = useHistory();
    const [submit, setSubmit] = useState(false);
    const [email, setEmail] = useState("");
    const [loginUserEmail, { loading, error }] = useMutation(RequestResetPasswordMutation, {
        onCompleted: () => {
            history.push('/emailSent');
        }
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmit(true);
        loginUserEmail({ variables: { input: { email: email } } });
    };

    const hasErrorEmail = () => {
        return submit && error && (
            error.message.includes('need signup') ||
            error.message.includes('not found')
        );
    };

    const getErrorEmail = () => {
        if (!submit) return null;
        if (error && error.message.includes('not found')) return 'Cette adresse mail n\'est pas enregistrée.';
        if (error && error.message.includes('need signup')) return 'Inscription nécessaire.';
        return null;
    };

    return (
        <Base title="Mot de passe oublié" subtitle="Nous vous enverrons un lien de récupération" icon={<LockIcon className={classes.icon} />}>
            <form className={classes.loginForm} onSubmit={handleSubmit}>
                <div className={classes.fields}>
                    <TextField fullWidth label="Adresse mail" name="email" variant="outlined"
                        value={email} onChange={e => { setSubmit(false); setEmail(e.target.value); }}
                        error={hasErrorEmail()} helperText={getErrorEmail()} />
                </div>
                <Box marginTop={2}>
                    <ButtonWithCircularProgress size="large" type="submit" loading={loading} disabled={email === "" || loading}
                        label="Recevoir un lien de récupération" fullWidth />
                </Box>
            </form>
            <Divider className={classes.divider} />
            <div className={classes.links}>
                <Link align="left" color="secondary" component={RouterLink} to="/" underline="always" variant="subtitle2">
                    {'Se connecter?'}
                </Link>
                <Link align="right" color="secondary" component={RouterLink} to="/signup" underline="always" variant="subtitle2">
                    {"S'inscrire?"}
                </Link>
            </div>
        </Base>
    );
};

export default RequestResetPassword;